/*流式布局*/
.flex-parent {
    width: auto;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
}

/*布局*/
.flex{
  display: flex;
}
.flex-row{
  flex-direction: row;
}
.flex-col{
  flex-direction: column;
}
.flex-row-reverse{
  flex-direction: row-reverse;
}
.flex-col-reverse{
  flex-direction: column-reverse;
}

/*换行方式*/
.flex-wrap-nowrap{
  flex-wrap: nowrap;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-wrap-reverse{
  flex-wrap: wrap-reverse;
}
.flex-flow{
  flex-flow:row nowrap;
}



.flex-row.flex-left {justify-content: flex-start;}
.flex-row.flex-center{justify-content: center;}
.flex-row.flex-right {justify-content: flex-end;}
.flex-row.flex-top  {align-items: flex-start;}
.flex-row.flex-middle {align-items: center;}
.flex-row.flex-bottom {align-items: flex-end;}


.flex-col.flex-left{align-items: flex-start;}
.flex-col.flex-center{align-items: center;}
.flex-col.flex-right{align-items: flex-end;}
.flex-col.flex-top {justify-content: flex-start;}
.flex-col.flex-middle{justify-content: center;}
.flex-col.flex-bottom{justify-content: flex-end;}


/*item 多根轴线的对齐方式*/
.align-content-left{
  align-content: flex-start
}
.align-content-right{
  align-content: flex-end
}
.align-content-center{
  align-content: center
}
.align-content-space-between{
  align-content: space-between
}
.align-content-space-around{
  align-content: space-around
}
.align-content-stretch{
  align-content: stretch
}

.flex-grow-1{
  flex-grow:1;
}
.flex-1{
  flex:1;
}
.flex-2{
  flex:2;
}
.flex-shrink-0{
  flex-shrink:0;
}


.box-center {
  display: flex;
  align-items: center;
  justify-content: center
}

.color_whilte{
 color: #fff;
}


